.page-cards {
	display: grid;
	gap: 1.5rem;

	&.page-cards-3 {
		grid-template-columns: 1fr 1fr 1fr;
	}

	&.page-cards-4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.mat-card {
		gap: 1rem;
	}

	.mat-card-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: unset;
	}

	.mat-card-content {
		display: flex;
		flex-flow: column;
		gap: 1rem;
		margin-bottom: 0 !important;

		> div {
			display: flex;
			gap: 1rem;

			> div {
				flex: 1;
				display: flex;
				flex-flow: column;

				caption {
					color: $color-mediumgray;
					font-size: $caption-font-size;
					white-space: nowrap;
					align-self: flex-start;
				}

				output {
					white-space: nowrap;

					&::first-letter {
						text-transform: capitalize;
					}
				}
			}
		}
	}
}
