@use '@angular/material' as mat;
@use 'palette-colors';

$color-primary: mat.get-color-from-palette(palette-colors.$blue-ribbon, 500);
$color-dark-primary-text: #031634;
$color-light-primary-text: white;

// region Grey colors was not formed in palette in original design
$color-grey-primary-text: #70779e;
$color-grey-inactive: #b2b9c6;
$color-grey-card-bg: #ecf0f8;
$color-grey-hover: #f3f5ff;
$color-grey-border: #afbacf;
$color-backdrop: #06142780;

// Controls have they own colors used only for them
$color-grey-outline-thick: #b8bfe5;
$color-grey-control-bg: #ebedf5;
$color-grey-control-bg-dark: #d0d6eb;
// endregion

$color-danger: mat.get-color-from-palette(palette-colors.$fair-pink, 500);

// region Status colors
$color-danger-foreground: mat.get-color-from-palette(palette-colors.$fair-pink, 700);
$color-danger-background: mat.get-color-from-palette(palette-colors.$fair-pink, 300);

$color-warning-foreground: #ae8303;
$color-warning-background: #fef2cd;

$color-success-foreground: mat.get-color-from-palette(palette-colors.$elf-green, 700);
$color-success-background: mat.get-color-from-palette(palette-colors.$elf-green, 300);

$color-info-foreground: $color-primary;
$color-info-background: #cbdffc;

$color-secondary-foreground: $color-grey-primary-text;
$color-secondary-background: #e0e2ea;

$color-purple-foreground: #8550f7;
$color-purple-background: #e5d9fd;
// endregion

// region Arbitrary colors used without system
$color-koromiko: #ffc266;
$color-periwinkle-gray: #dae3f1;
$color-spring-green: #33ff99;
// endregion

$contrast-colors-map: (
	contrast: (
		100: $color-dark-primary-text,
		500: $color-light-primary-text,
		700: $color-light-primary-text,
	),
);
