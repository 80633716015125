@use '@angular/material' as mat;

$layout-cards-gap: 16px;

$mat-card-padding-x: 1rem;
$mat-card-padding-y: 1rem;

$outer-layout-padding: $layout-cards-gap;

$toolbar-height: 70px + $outer-layout-padding; // Including top gap for proper height calculation
$page-header-height: 34px;

$z-index-toolbar: 500;
$z-index-backdrop: $z-index-toolbar + 100;

$modal-min-width: 450px;
$modal-min-height: 240px;
$modal-padding-x: 1rem;
$modal-padding-y: 1rem;

// Menu and toolbar
$layout-border-radius: 16px;

//region Shadows

// For general layout cards, toolbar etc
$long-card-shadow: 0 5px 24px rgba(0, 0, 0, 0.1);

// Used only in one place
$control-shadow: 0 5px 12px 0 #0316341a;

// Used for small cards, e.g. in router
$short-card-shadow: 0px 5px 12px 0px hsla(216, 60%, 15%, 0.1);

//endregion
