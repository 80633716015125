@use '@angular/material' as mat;
@use '../../importable' as *;

bp-property-metadata-controls-section {
	font-size: $font-size-base;

	// We do not do align-items: center, because there could be multi-line controls, like chips,
	// and centering there looks weird.
	.row {
		// If switch control is followed by non switch
		.control-type-switch:has( ~ :not(.control-type-switch)),
			// If non-switch control is followed by switch
		:not(.control-type-switch) ~ .control-type-switch,
			// If checkbox control is followed by non checkbox
		.control-type-checkbox:has( ~ :not(.control-type-checkbox)),
			// If non-checkbox control is followed by checkbox
		:not(.control-type-checkbox) ~ .control-type-checkbox {
			margin-top: 0.7rem;
		}
	}

	> h2 {
		margin-bottom: 1rem !important;
	}
}

bp-property-metadata-view {
	font-size: $font-size-base;

	&.view-type-boolean mat-icon.mat-icon {
		$iconSize: 18px;
		font-size: $iconSize;
		@include size($iconSize);
	}
}

bp-property-metadata-view caption,
.property-view-caption {
	color: $color-grey-primary-text !important;
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	white-space: nowrap;
	gap: 0.4375rem;
}
