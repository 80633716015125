@use '../../importable' as *;

.alert {
	font-size: $font-size-base;
	padding: 1rem;
	margin-bottom: 1rem;
	box-shadow: none;
	background: var(--alert-background, #{$color-info-background});
	color: var(--alert-foreground, #{$color-dark-primary-text});

	@include subheaders() {
		color: var(--alert-foreground, #{$color-dark-primary-text});
	}

	&.alert-warning {
		--alert-background: #{$color-warning-background};
		--alert-foreground: #{$color-warning-foreground};
	}

	&.alert-danger {
		--alert-background: #{$color-danger-background};
		--alert-foreground: #{$color-danger-foreground};
	}

	.btn-icon-close {
		@include iconSize(1rem !important);

		bp-svg-icon {
			@include size(0.75rem !important);
		}
	}
}
