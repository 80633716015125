@use '@angular/material' as mat;
@use '../../../importable' as *;

mat-slide-toggle {
	$thumb-size: 12px;
	@include heightAndLineHeight($thumb-size + 2px);

	.mat-slide-toggle-bar {
		width: 26px;
	}

	.mat-slide-toggle-thumb-container {
		top: 1px;
		left: 1px;
		@include size($thumb-size);
	}

	.mat-slide-toggle-thumb {
		background-color: white !important;
		@include size($thumb-size);
	}

	&.mat-checked .mat-slide-toggle-thumb-container {
		transform: translate3d($thumb-size, 0, 0);
	}

	.mat-slide-toggle-ripple {
		top: calc(50% - $thumb-size);
		left: calc(50% - $thumb-size);
		@include size($thumb-size * 2);
	}

	div.mat-ripple-element {
		left: 0 !important;
		top: 0 !important;
		@include size(#{$thumb-size * 2} !important);
	}

	// region Primary theme
	&.mat-primary {
		.mat-slide-toggle-thumb {
			background-color: white !important;
		}

		&.mat-checked .mat-slide-toggle-bar {
			background-color: $color-primary !important;
		}
	}
	// endregion
}
