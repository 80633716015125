@use 'sass:meta';
@use 'sass:map';
@use '@material/elevation/elevation-theme' as mdc-elevation;
// @use '@angular/material' as mat;

@mixin elevation-5-y-zero() {
	// as mat-elevation(5); but with the y offset set to 0
	box-shadow:
		0px 0px 5px -1px rgba(0, 0, 0, 0.08),
		0px 0px 8px 0px rgba(0, 0, 0, 0.056),
		0px 0px 14px 0px rgba(0, 0, 0, 0.048);
}

@mixin elevation-11-y-zero() {
	// as mat-elevation(11); but with the y offset set to 0
	box-shadow:
		0px 0px 7px -4px rgba(0, 0, 0, 0.1),
		0px 0 15px 1px rgba(0, 0, 0, 0.07),
		0px 0 20px 3px rgba(0, 0, 0, 0.06);
}

@mixin elevation($zValue, $color: $elevation-color, $opacity: $elevation-opacity) {
	// Temporary solution
	// Copied from mat.elevation and changed as the current version doesn't account for opacity for each color.
	// I believe that this is a bug, because the documentation says that opacity property should take
	// a percentage of the default value (they are different for each color)
	//
	// as soon as the bug is fixed - replace it with
	// @include mat.elevate($zValue, $color, $opacity)
	$umbra-z-value: map.get(mdc-elevation.$umbra-map, $zValue);
	$penumbra-z-value: map.get(mdc-elevation.$penumbra-map, $zValue);
	$ambient-z-value: map.get(mdc-elevation.$ambient-map, $zValue);
	$box-shadow: (
		#{'#{$umbra-z-value} #{rgba($color, mdc-elevation.$umbra-opacity * $opacity)}'},
		#{'#{$penumbra-z-value} #{rgba($color, mdc-elevation.$penumbra-opacity * $opacity)}'},
		#{$ambient-z-value} rgba($color, mdc-elevation.$ambient-opacity * $opacity)
	);
	@include mdc-elevation.shadow($box-shadow);
}
