@use 'theming/colors';

$button-heights: (
	xs: 28px,
	sm: 34px,
	base: 36px,
	xl: 38px,
	2xl: 40px,
);

@mixin stroked-primary-button-hover() {
	color: white;
	border-color: colors.$color-primary !important;
	background: colors.$color-primary;
}
