.mat-card-content.mat-card-content-table {
	overflow: auto;
	margin: 0 (-$mat-card-padding-x) (-$mat-card-padding-y) (-$mat-card-padding-x) !important;
}

.mat-card-content-table {
	$gatter-y: 0.75rem;
	$edge-gatter-y: $gatter-y * 2;
	$table-padding-x: 1.5rem;

	table {
		width: 100%;
		background: transparent;

		th,
		td {
			color: $color-dark-primary-text;
			font-weight: $font-weight-normal;
			font-size: $body-font-size;
			border-color: $color-border;

			$cell-padding-x: 0.5rem;

			padding : {
				left: $cell-padding-x;
				right: $cell-padding-x;
			}

			&:first-of-type {
				padding-left: $table-padding-x;
			}

			&:last-of-type {
				padding-right: $table-padding-x;
			}
		}

		th {
			padding: {
				top: 0.5rem;
				bottom: $edge-gatter-y;
			}

			@at-root .compact#{&} {
				padding-bottom: 1rem;
				border-width: 0;
			}

			.mat-sort-header-arrow {
				margin-left: 4px;
			}
		}

		td {
			border-color: transparent;

			padding : {
				top: $gatter-y;
				bottom: $gatter-y;
			}

			transition: background $animation-duration $animation-function;
		}

		tr {
			height: auto;

			&.disabled {
				filter: grayscale(0.75);
				background: $color-mist;
			}

			&.clickable {
				&.active td {
					background: fade-out($color-primary, 0.8);
				}

				&:not(.active):hover td {
					cursor: pointer;
				}
			}

			&.highlight-on-hover:hover td {
				background: $color-table-cell-hover;
			}

			&.clickable:not(.active):hover td {
				background: fade-out($color-primary, 0.9);
			}

			&.table-row-space-consumer {
				height: 100%;
			}
		}

		tbody tr:first-of-type td {
			border-top: $gatter-y solid white;

			@at-root .compact#{&} {
				border-width: 0;
			}
		}

		tfoot tr td {
			padding-bottom: $edge-gatter-y;
			font-weight: $font-weight-bold;

			@at-root .compact#{&} {
				padding-top: 1rem;
			}
		}
	}

	&:not(.has-footer) tbody tr:last-of-type td {
		border-bottom: $gatter-y solid white;
	}
}
