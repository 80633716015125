@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-legacy-component-typographies($core-typography-config);
@include mat.legacy-core();

@include mat.legacy-card-typography($card-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$theme: map-merge(
	mat.define-light-theme($primary-palette, $accent-palette, $danger-palette),
	(
		foreground:
			map-merge(
				mat.$light-theme-foreground-palette,
				(
					base: $color-dark-primary-text,
					text: $color-dark-primary-text,
					divider: fade-out($color-dark-primary-text, 0.88),
					secondary-text: $color-dark-secondary-text,
				)
			),
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($theme);

html {
	--bp-color-primary: #{$color-primary};
	--bp-color-primary-light: #{$color-primary-light};
}
