@use '@angular/material' as mat;

mat-card.mat-card.bp-card {
	display: flex;
	transition: box-shadow $transition-props;
	@include elevation($elevation-card);

	&.hoverable:hover {
		cursor: pointer;
		@include elevation($elevation-card * 2);
	}

	&.mild-box-shadow {
		@include elevation($elevation-card-aux);

		&.hoverable:hover {
			cursor: pointer;
			@include elevation($elevation-card-aux * 2);
		}
	}

	mat-card-footer {
		border-top: 1px solid $color-border;

		.mat-button {
			display: block;
			width: 100%;
			height: 100%;
			text-align: center;
			padding: 0.75rem 0;
			border: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&.payment-provider-card {
		mat-card-title {
			display: flex;
			align-items: center;
			justify-content: space-between;

			h2 {
				margin: 0;
			}

			.psp-logo {
				background-repeat: no-repeat;
				background-position: right;
				background-size: contain;
				width: 100px;
				height: 30px;
			}

			.risk {
				margin-left: 1rem;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		&.stub mat-card-title .psp-logo {
			height: auto;
		}
	}
}
