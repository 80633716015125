@use '@angular/material' as mat;
@use 'sass:math';
@use '../../importable' as *;

.cdk-overlay-container {
	.mat-dialog-container {
		@include elevation($elevation-modal, $elevation-color, $elevation-opacity);

		.btn-modal-icon-close.mat-icon-button {
			$size: 14px;
			@include size($size !important);
			@include absolute($right: $modal-padding-x / 2, $top: $modal-padding-y / 2);
			background-color: $color-grey-primary-text !important;
			z-index: 1;

			svg {
				@include iconSize($size / 2 !important);
				color: white !important;
			}
		}
	}
}

.bp-modal-overlay-pane.modal-overlay-pane {
	// region Modal Window Sizes
	&-md {
		max-width: 496px !important;
	}

	&-big {
		max-width: 560px !important;
	}

	&-lg {
		max-width: 648px !important;
	}

	&-xl {
		max-width: 652px !important;
	}

	&-2xl {
		max-width: 808px !important;
	}
	// endregion
}

// Note we cover both modal and dialogs there
// .cdk-dialog-container extra specificity to override base styles
.cdk-overlay-container:not(:has(.old-design-modal)) .mat-dialog-container.cdk-dialog-container {
	@include popupBorder();

	// region Reset MA styles and apply new default values
	.mat-dialog-title {
		margin-bottom: $modal-padding-y;
	}

	mat-dialog-actions {
		gap: 1rem;
		min-height: auto !important;
		margin-top: $modal-padding-y !important;
		margin-bottom: 0 !important;

		button {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}
	// endregion

	// region Dialog scrollbars fix
	padding: $modal-padding-y $modal-padding-x;

	.mat-dialog-title,
	.mat-dialog-content,
	.mat-dialog-actions {
		margin-inline: -$modal-padding-x !important;
		padding-inline: $modal-padding-x !important;
	}

	.mat-dialog-title {
		margin-top: -$modal-padding-y !important;
		padding-top: $modal-padding-y !important;
	}

	.mat-dialog-actions {
		margin-bottom: -$modal-padding-y !important;
		padding-bottom: $modal-padding-y !important;
	}
	// endregion

	.mat-dialog-title {
		color: $color-grey-primary-text;
	}

	:is(.mat-dialog-title, mat-dialog-title) :is(h1, h2, h3),
	:is(h1, h2, h3).mat-dialog-title {
		font-size: $font-size-xs;
		text-transform: uppercase;
		line-height: 1;
		align-self: flex-start;
	}

	:is(.mat-dialog-title, mat-dialog-title) :is(h1, h2, h3) {
		margin-bottom: 0;
	}

	.mat-dialog-actions {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		gap: 1.5rem;
		justify-content: center;
		align-items: center;
		min-height: 0;
	}
}

.mat-dialog-container {
	font-size: $font-size-xl;
	line-height: $line-height-golden-ratio;
	min-width: $modal-min-width;
	min-height: $modal-min-height;
	height: auto;

	> *:has(> .mat-dialog-content) {
		display: flex;
		flex-flow: column;
		align-items: stretch;
		justify-content: space-between;
		width: 100%;
		height: 100%;

		.mat-dialog-content {
			margin-bottom: 1rem;
		}
	}
}
