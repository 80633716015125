@use '@angular/material' as mat;
@use 'libs/frontend/styles/importable/variables' as global-variables;

$v3-font-family: Nacelle, 'Helvetica Neue', sans-serif;

// region Font sizes
// 9px, normal: rule card multiple values count icon
$font-size-3xs: 0.5625rem;

// 10px, extra bold: rule card title, rule card money amount
// 10px, bold: warnings count in route checkout card icons
// 10px, normal: order of CC payment options in route checkout card
$font-size-2xs: 0.625rem;

// 11px, bold: rule card condition type, country name in route countries list, non-named modal title, caption of view control sections
$font-size-xs: 0.6875rem;

// 12px, bold: payment option type add button, subregion in dropdown in route countries list
$font-size-sm: 0.75rem;

// 13px, bold: Selected payment option type card title in checkout panel
// 13px, normal: PSP expand button in modal, Select All in route countries list
$font-size-base: 0.8125rem;

// 14px, normal: Intro shell page, Countries filter, Payment option general details title
// 14px, bold: Bridger PSP card title, Merchant PSP card title
$font-size-lg: 0.875rem;

// 15px, extra bold: title in "add rule" modal
// 15px, bold: title in "add rule" modal rule form, route card header in routes page
// 15px, normal: Merchant Three DS credential modal page title, No rule applied, Modal page named entity title (credentials)
$font-size-xl: 0.9375rem;

// 16px, normal: some buttons text
$font-size-2xl: 1rem;

// 18px, extra bold: h1, Subscription has expired window
$font-size-3xl: 1.125rem;

// 22px, slim: modal alert text
$font-size-4xl: 1.375rem;
// endregion

$font-sizes: (
	3xs: $font-size-3xs,
	2xs: $font-size-2xs,
	xs: $font-size-xs,
	sm: $font-size-sm,
	base: $font-size-base,
	lg: $font-size-lg,
	xl: $font-size-xl,
	2xl: $font-size-2xl,
	3xl: $font-size-3xl,
	4xl: $font-size-4xl,
);

// Note font sizes were inconsistent during all versions of MA in Figma, therefore the most common were picked
$laptop-base-typography-config: mat.define-legacy-typography-config(
	$font-family: $v3-font-family,
	$display-1: mat.define-typography-level($font-size-3xl, global-variables.$line-height-golden-ratio, 400),
	$headline: mat.define-typography-level($font-size-3xl, global-variables.$line-height-golden-ratio, 700),
	$title: mat.define-typography-level($font-size-sm, global-variables.$line-height-golden-ratio, 700),
	$subheading-2: mat.define-typography-level($font-size-xs, global-variables.$line-height-golden-ratio, 600),
	$body-2: mat.define-typography-level($font-size-base, global-variables.$line-height-golden-ratio, 600),
	$body-1: mat.define-typography-level($font-size-base, global-variables.$line-height-golden-ratio, 400),
	$caption: mat.define-typography-level($font-size-sm, 18px, 400),
	$button: mat.define-typography-level($font-size-base, 20px, 500),
	$input: mat.define-typography-level($font-size-base, 1.125, 400),
);
