mat-dialog-container {
	mat-dialog-actions {
		display: flex;
		justify-content: flex-end;
		min-height: auto !important;
		margin-top: 27px !important;
		margin-bottom: 8px !important;
		padding: 0 !important;

		button + button {
			margin-left: 1rem !important;
		}
	}
}

.dialog-card {
	@extend .right-drawer-card;
	border: none !important;
	margin-bottom: 0 !important;

	&.card-view {
		padding: 0 !important;
	}
}
