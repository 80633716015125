@use '../../../importable' as *;

.mat-pseudo-checkbox-full {
	color: inherit;
	border-width: 1px;
	border-radius: 3px;
	border-color: $color-primary;

	&.mat-pseudo-checkbox-checked,
	&.mat-pseudo-checkbox-indeterminate {
		background: $color-primary;
	}

	&.mat-pseudo-checkbox-indeterminate:after {
		left: 2px;
		top: 7px;
	}
}
