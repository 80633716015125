.three-d-secure {
	font-weight: $font-weight-extra-bold;
	color: #ea9114;

	&::first-letter {
		color: #c3110d;
	}
}

bp-payment-card-brand-icon bp-svg-icon,
.boxed-value {
	border: 1px solid $color-lightboxgray;
	border-radius: 4px;
	background: white;
}

.boxed-value {
	padding: 1px 6px;
	font-size: 12px;
	display: inline-flex;
	flex-shrink: 0;
}

// TODO: Fix for Chrome 105 possible bug which makes inner svg do not respect parent flex container size.
// TODO: Remove when fixed.
bp-svg-icon > svg {
	max-width: 100%;
	width: 100%;
}

.overlay-at-right {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding: 1rem !important; // to keep any shadow inside
	margin: -1rem !important;

	> * {
		flex-shrink: 0;
	}

	@include pseudo-after-small-gradient-overlay-at-right();
}
