@use '../../../importable' as *;

$tab-border-radius: 24px;

.mat-tab-nav-bar {
	.mat-tab-link {
		padding: 0 12px;
		height: 32px;
		min-width: 120px;
		background: transparent;
	}
}

.buttons-like-nav {
	display: inline-block;
	border-radius: $tab-border-radius !important;
	border: 1px solid #dbe2ec !important;
	overflow: hidden;
	position: relative;

	a.mat-tab-link,
	button.mat-tab-link {
		box-shadow: none !important;
		background: white !important;
		min-width: 150px;
		border-radius: $tab-border-radius;
		border: none !important;
		transition: background-color $transition-props;

		&.mat-tab-label-active {
			background-color: $color-primary !important;
			color: white;
			opacity: 1;
		}
	}

	mat-ink-bar {
		display: none;
	}
}
