@use '@angular/material' as mat;
@use '../../importable' as *;

.intro-theme {
	@include mat.all-legacy-component-colors($intro-theme);

	.link {
		color: mat.get-color-from-palette($foreground-intro-palette, text);

		&[disabled] {
			color: mat.get-color-from-palette($foreground-intro-palette, disabled-button);
		}
	}
}
