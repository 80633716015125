@use '../../importable' as *;

// By analogue with @import 'tippy.js/themes/material.css';
.tippy-box[data-theme~='material'] {
	background-color: $color-dark-primary-text;
}

.tippy-box[data-theme~='material'][data-placement^='top'] > .tippy-arrow:before {
	border-top-color: $color-dark-primary-text;
}

.tippy-box[data-theme~='material'][data-placement^='bottom'] > .tippy-arrow:before {
	border-bottom-color: $color-dark-primary-text;
}

.tippy-box[data-theme~='material'][data-placement^='left'] > .tippy-arrow:before {
	border-left-color: $color-dark-primary-text;
}

.tippy-box[data-theme~='material'][data-placement^='right'] > .tippy-arrow:before {
	border-right-color: $color-dark-primary-text;
}

.tippy-box[data-theme~='material'] > .tippy-backdrop {
	background-color: $color-dark-primary-text;
}

.tippy-box[data-theme~='material'] > .tippy-svg-arrow {
	fill: $color-dark-primary-text;
}
