table.details-table {
	width: 100%;

	th {
		font-weight: $font-weight-normal;
		text-align: start;
		padding-bottom: 1rem;
	}

	tr td {
		&:first-child {
			padding: 0.25rem 0;
			vertical-align: top;
			width: 175px;

			& + td {
				max-width: 220px;
				overflow-wrap: break-word;
			}
		}

		&.status {
			white-space: nowrap;

			> .circle-sm {
				margin-right: 0.25rem;
			}
		}
	}
}

.page-table {
	th.text-center {
		text-align: center !important;

		.mat-sort-header-container {
			justify-content: center;
		}
	}

	td {
		&.mat-column-enabled,
		&.mat-column-menu,
		&.mat-column-status,
		&.mat-column-country {
			width: 1px;
		}

		&.mat-column-status,
		&.mat-column-enabled {
			padding-left: 0;
			padding-right: 15px;

			span {
				display: flex;
			}
		}

		&.mat-column-pspName,
		&.mat-column-logoUrl {
			padding-right: 15px;
		}

		&.mat-column-menu > button {
			margin-left: -15px;
		}

		&.mat-column-country {
			padding-left: 10px;
			padding-right: 17px;

			bp-country > span {
				display: flex;
				width: 1.75em;
				height: 1.5em;
				justify-content: center;
				align-items: center;
			}
		}

		bp-property-metadata-view {
			&.view-type-link,
			&.view-type-email {
				line-height: 0;
			}
		}
	}
}
