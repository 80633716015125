@use '@angular/material' as mat;
@use '../../importable' as *;

.mat-typography bp-announcements-hub {
	bp-announcements-hub-header {
		.mat-toolbar {
			h2 {
				@include mat.typography-level($laptop-base-typography-config, headline);
				margin: 0;
			}
		}
	}

	bp-announcements-hub-body {
		> h1.no-announcements {
			margin-top: 40px;
		}

		bp-announcements-hub-item {
			h3 {
				@include mat.typography-level($laptop-base-typography-config, title);
			}
		}
	}
}

.announcement-modal-overlay-pane .mat-dialog-container {
	h1,
	h2,
	h3,
	h4 {
		text-transform: none;
		color: $color-dark-primary-text;
	}

	h1 {
		font-size: $font-size-3xl;
	}

	h2 {
		font-size: $font-size-2xl;
	}

	h3 {
		font-size: $font-size-xl;
	}
}
