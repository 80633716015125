@use '../importable' as *;

// Snatched from Tailwind CSS
.grid {
	display: grid;
	gap: $layout-cards-gap;

	@for $i from 1 through 12 {
		&.grid-cols-#{$i} {
			grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
		}

		&.grid-rows-#{$i} {
			grid-template-rows: repeat(#{$i}, minmax(0, 1fr));
		}

		.col-span-#{$i} {
			grid-column: span #{$i} / span #{$i};
		}

		.row-span-#{$i} {
			grid-row: span #{$i} / span #{$i};
		}

		&.stretch-#{$i}-row {
			grid-template-rows: repeat(#{$i - 1}, max-content) 1fr;
			grid-auto-rows: max-content;
		}
	}

	@for $i from 1 through 12 {
		@include media('>=2800px') {
			&.xl-grid-cols-#{$i} {
				grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
			}

			.xl-col-span-#{$i} {
				grid-column: span #{$i} / span #{$i};
			}
		}
	}

	.col-auto {
		grid-column: auto;
	}

	.col-span-full {
		grid-column: 1 / -1;
	}

	.row-auto {
		grid-row: auto;
	}

	.row-span-full {
		grid-row: 1 / -1;
	}
}
