@use '@angular/material' as mat;
@use 'sass:map';

$width: 165px;
$md-width: 200px;
$lg-width: 250px;
$padding-x: 1rem;

$config: mat.get-color-config($theme);
$primary: map.get($config, primary);
$accent: map.get($config, accent);
$warn: map.get($config, warn);
$foreground: map.get($config, foreground);
$is-dark-theme: map.get($config, is-dark);

$label-disabled-color: mat.get-color-from-palette($foreground, disabled-text);
$outline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
$outline-color-hover: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.87));
$outline-color-primary: mat.get-color-from-palette($primary);
$outline-color-accent: mat.get-color-from-palette($accent);
$outline-color-warn: mat.get-color-from-palette($warn);
$outline-color-disabled: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.15, 0.06));

.control-appearance-round {
	border-radius: $round-control-border-radius;
	padding: 8px $padding-x;
	transition: $animation-duration $animation-function;
	transition-property: background;
	position: relative;
	overflow: hidden;
	background: white;

	.form-field-outline-thick,
	.form-field-outline {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		border: 1px solid $outline-color;
		border-radius: 100px;
		pointer-events: none;

		transition: $animation-duration $animation-function;
		transition-property: border-color, opacity;
	}

	.form-field-outline-thick {
		opacity: 0;
		border-width: 2px;
	}

	&:hover {
		.form-field-outline-thick {
			opacity: 1;
			border-color: $outline-color-hover;
		}
	}

	&:focus-within {
		.form-field-outline-thick {
			opacity: 1;
			border-color: $outline-color-primary;
		}
	}

	+ bp-validation-error {
		margin-left: $padding-x;
	}

	.clear-button-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -1px;
		right: 0;
		bottom: 0;

		.mat-icon-button {
			@include iconSize($round-control-height !important); // the height of the input

			&:hover .mat-button-focus-overlay {
				opacity: 0.1;
			}

			.mat-icon {
				font-size: 20px;
				width: auto;
				height: auto;
			}
		}
	}

	input {
		color: inherit !important;

		@include placeholder-color($color-slate-gray);
	}
}

.control-appearance-round-lg {
	&,
	> .control-appearance-round {
		width: $lg-width !important;
	}
}

.daterange-appearance-round {
	@extend .control-appearance-round;
	display: flex;
	min-width: 220px;

	.control.ng-dirty.ng-invalid &,
	&.ng-dirty.ng-invalid {
		background: $color-danger;
		border-color: $color-danger;

		input {
			caret-color: $color-danger;

			&::selection {
				color: $color-danger;
			}
		}
	}

	.control.ng-dirty.ng-invalid &,
	&.ng-dirty.ng-invalid {
		color: white;

		input {
			@include placeholder-color(white);
			color: white;
			caret-color: white;

			&::selection {
				background: rgba(255, 255, 255, 0.99);
			}
		}
	}
}

.control {
	transition: opacity $transition-props;

	&.md {
		width: 100%;
		max-width: 230px;
	}

	&.lg {
		width: 100%;
		max-width: 300px;
	}

	&.w-180 {
		&,
		> .control {
			width: 180px !important;
		}
	}

	&.disabled {
		opacity: 0.5;
	}
}
