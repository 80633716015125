@use 'sass:color';
@use '@angular/material' as mat;
@use '../../importable' as *;

$button-disabled-bg: color.adjust($color-grey-primary-text, $alpha: -0.8);
$button-disabled-color: white;

a,
button,
.mat-dialog-container .mat-dialog-actions button {
	&.mat-button-base:not(.mat-icon-button) {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: auto;
		border-color: $color-grey-border !important;
		transition-property: border-color, color, background, box-shadow;

		@each $font-size-name in map-keys($font-sizes) {
			&.bp-button-#{ $font-size-name } {
				font-size: map-get($font-sizes, $font-size-name);
				font-weight: $font-weight-bold;
			}
		}

		//region All buttons sizes must respect related font sizes
		&.bp-button-md {
			@include heightAndLineHeight(34px);
			font-weight: $font-weight-bold;
			font-size: map-get($font-sizes, base);
		}

		&.bp-button-xs {
			@include heightAndLineHeight(map-get($button-heights, xs));
			padding: 0 12.5px;
		}

		&.bp-button-sm {
			@include heightAndLineHeight(map-get($button-heights, sm));
		}

		&.bp-button-base {
			@include heightAndLineHeight(map-get($button-heights, base));
		}

		&.bp-button-xl {
			@include heightAndLineHeight(map-get($button-heights, xl));
			padding: 0.175em 2em;
			min-width: 7.5em;
		}

		&.bp-button-2xl {
			@include heightAndLineHeight(map-get($button-heights, 2xl));
		}
		// endregion

		&.mat-stroked-button.mat-button-base {
			border-color: currentColor !important;

			&:not(.mat-button-disabled) {
				&[color='primary'] {
					border-color: $color-dark-primary-text !important;
					color: $color-dark-primary-text;

					&:hover {
						@include stroked-primary-button-hover();
					}
				}

				&[color='warn'] {
					border-color: $color-danger !important;
					color: $color-danger;

					&:hover {
						color: white;
						background: $color-danger;
					}
				}
			}

			&:not([color]) {
				color: $color-grey-primary-text;
			}
		}

		&.mat-button-base.mat-button-disabled:not(.mat-icon-button) {
			border: none;
			background: $button-disabled-bg !important;
			color: $button-disabled-color !important;
		}

		&.mat-icon-button.mat-button-base {
			transition: color $transition-props;

			&.mat-button-disabled {
				color: $color-grey-inactive;
			}
		}
	}
}

.bp-round-button {
	mat-icon {
		transition: none;
	}
}
