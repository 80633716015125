@use '@angular/material' as mat;
@use '../../importable' as *;

// General material cards
mat-card.mat-card {
	border-radius: $card-border-radius;
	padding: $mat-card-padding-x $mat-card-padding-y;
	color: $color-dark-primary-text;
	transition:
		box-shadow $transition-props,
		background $transition-props;

	// We dont want to include "overflow:hidden" to the parent element to support sticky behaviour of
	// inner elements on some pages, so we need to round children accordingly.
	> :first-child:not(button) {
		border-top-left-radius: $card-border-radius;
		border-top-right-radius: $card-border-radius;
	}

	> :last-child {
		border-bottom-left-radius: $card-border-radius;
		border-bottom-right-radius: $card-border-radius;
	}

	&.viewport-height {
		max-height: calc(100vh - #{$toolbar-height + $layout-cards-gap * 2 + $outer-layout-padding});
	}

	.card-header {
		margin-top: #{-1 * $mat-card-padding-y};
		margin-left: #{-1 * $mat-card-padding-x};
		margin-right: #{-1 * $mat-card-padding-x};
		padding: $mat-card-padding-y $mat-card-padding-x;
		border-radius: $card-border-radius $card-border-radius 0 0;
		position: relative;
		background: white;
		transition: z-index $transition-props;
		z-index: $z-index-backdrop + 1;
	}

	.scrollable-content {
		overflow: auto;
		margin-left: #{-1 * $mat-card-padding-x};
		margin-right: #{-1 * $mat-card-padding-x};
	}

	&:not([class*='mat-elevation-z']) {
		box-shadow: $long-card-shadow;

		&.hoverable {
			&:hover,
			&.active {
				background: $color-grey-hover;
				// Override elevation
				box-shadow: $long-card-shadow;
			}
		}
	}
}

bp-page-header ~ mat-card.mat-card.viewport-height {
	max-height: calc(100vh - #{$toolbar-height + $page-header-height + $layout-cards-gap * 3 + $outer-layout-padding});
}

.card-edit-button {
	@include iconBtnColor();
	@include absolute($top: 0.5rem, $right: 0.5rem);
}

// Layout card is a card which used as a main block for the pages layout
.layout-card {
	height: 100%;
}

// Sub-card is a grey-ish card inside a layout card
// Usually it is inside .layout-card, but for some rare cases it could be outside of it (e.g. during dragging)
.sub-card {
	padding: $mat-card-padding-y $mat-card-padding-x;
	background: $color-grey-hover;
	border-radius: $card-border-radius;

	&.hoverable {
		box-shadow: none;
		transition:
			background-color $transition-props,
			box-shadow $transition-props;

		&:hover {
			background: $color-grey-card-bg;
			cursor: pointer;
			box-shadow: 0px 5px 12px 0px #0316341a;
		}
	}

	@for $i from 0 through 5 {
		h#{$i},
		.mat-h#{$i} {
			color: $color-grey-primary-text;
			text-transform: uppercase;
		}
	}
}
