.row {
	display: grid !important;
	grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
	gap: 1.5rem;

	> .control.w-100 > * {
		width: 100%;
	}
}

bp-property-metadata-views-section,
bp-property-metadata-instant-views-section {
	.row + .row {
		margin-top: 1rem;
	}
}

bp-property-metadata-controls-section {
	.row + .row {
		margin-top: 0;
	}
}
