@use '@angular/material' as mat;
@use '../../importable' as *;

.mat-card-content-table {
	table {
		tbody {
			tr:first-of-type td {
				border-top-color: transparent;
				border-bottom-color: transparent;
			}

			tr {
				height: 3rem;

				&:first-of-type td {
					border-top: none;
				}
			}
		}

		th {
			color: $color-grey-primary-text !important;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			padding-bottom: 0.5rem;
			border-bottom: none;
		}
	}

	:not(.has-footer) table tbody tr:last-of-type td {
		border-top-color: transparent;
		border-bottom-color: transparent;
	}
}
