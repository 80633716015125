@use '@angular/material' as mat;
@use '../../../importable' as *;

$appearance-inline-base-typography-config: mat.define-legacy-typography-config(
	$font-family: $v3-font-family,
	$input: mat.define-typography-level(15px, 1.125, 400),
);

// Inline (report-like) appearance based on legacy
.form-fields-appearance-inline {
	@include mat.all-legacy-component-typographies($appearance-inline-base-typography-config);

	// region If wrapped in mat-form-field
	.mat-form-field-underline {
		display: none;
	}
	// endregion

	// region MatSelect

	// Material predefines default minimum width, so reset them. Do it only for mat-select,
	// because input-like inputs still should have minimum width to allow placeholder to be rendered.
	.mat-form-field-infix:has(mat-select) {
		width: auto;
	}

	mat-select {
		display: inline-block;
		width: auto;

		.mat-select-placeholder,
		.mat-select-value-text {
			color: $color-primary;
			display: inline-flex !important;
			align-items: flex-end;

			&:after {
				font-family: 'Material Icons Round';
				text-transform: none;
				content: 'expand_more';
			}
		}

		.mat-select-arrow-wrapper {
			display: none;
		}
	}
	// endregion

	//region bp-input based components
	bp-input {
		&:not(.empty) input {
			transition: color $transition-props;
			color: $color-primary;
		}

		mat-form-field {
			$input-size: 1.2em;

			.mat-form-field-prefix,
			.mat-form-field-infix {
				border-bottom: 1px solid $color-grey-primary-text;
				line-height: 1.2;
			}

			.mat-form-field-infix {
				padding: 0 35px 0 0 !important;
				font-weight: $font-weight-normal;
				border-top: none;

				.mat-form-field-label-wrapper {
					top: -$input-size;
					padding-top: $input-size;
				}
			}

			// Clear button
			&.has-suffix .mat-form-field-flex .mat-form-field-suffix {
				@include absolute($right: 0);

				.mat-icon-button {
					$suffix-btn-size: 20px; // the height of the input
					@include iconSize($suffix-btn-size !important);

					.mat-icon {
						font-size: $input-size;
					}
				}
			}
		}
	}
	//endregion
}
