@use '../../../importable' as *;

bp-discard-changes-confirm-dialog {
	max-width: $modal-min-width;

	.mat-dialog-content > div {
		padding: 0 1rem;
		text-align: center;
	}
}
