@use '@angular/material' as mat;

@mixin statusColor($color) {
	&[class*='status-bar'] {
		border-color: $color !important;
	}

	&:not([class*='status-bar']) {
		background: $color !important;
	}
}

.rule-type,
.status-live,
.status-hiring,
.status-published,
.status-true,
.status-in-process,
.status-capture-in-progress,
.status-active,
.status-enabled,
.status-pending,
.status-connected,
.status-processing,
.status-support-requested,
.status-on-boarding {
	@include statusColor($color-primary);
}

.status-three-ds-authenticated {
	@include statusColor($color-status-three-ds-authenticated);
}

.risk-high,
.payment-route-rule-type-block,
.status-declined-due-to-3-d-auth-failure,
.status-declined-do-not-try-again,
.status-declined-by-timeout,
.status-declined-due-to-invalid-credit-card,
.status-declined-due-to-invalid-data,
.status-declined-due-to-error,
.status-declined,
.status-suspended,
.status-three-ds-init-failed,
.status-three-ds-failed,
.status-three-ds-not-authenticated {
	@include statusColor($color-danger);
}

.status-refunded {
	@include statusColor($color-status-refund);
}

.status-voided {
	@include statusColor($color-blue-zodiac);
}

.risk-low,
.status-support-request-completed,
.status-approved {
	@include statusColor($color-success);
}

.status-cancelled,
.status-skipped-due-to-missing-fingerprint {
	@include statusColor($color-warning);
}

.status-filtered-by-rule {
	@include statusColor($color-mediumgray);
}

.status-init,
.status-three-ds-init,
.status-false,
.status-expired,
.status-support-request-expired,
.status-inactive,
.status-not-active,
.status-disabled,
.status-not-hiring,
.status-not-published,
.status-not-connected,
.status-deleted {
	@include statusColor($color-lightgray);
}

.status-partly-refunded {
	background: $color-status-refund !important;
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		@include size(100%);
		position: absolute;
		background: $color-success;
		transform: rotate(225deg) translateX(50%);
	}
}

.status-authorized,
.status-approved-on-hold {
	background: $color-primary !important;
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		@include size(100%);
		position: absolute;
		background: $color-success;
		transform: rotate(45deg) translateX(50%);
	}
}

.text-rule-type,
.text-primary,
.text-status-in-process,
.text-status-pending,
.text-status-active,
.text-status-connected,
.text-status-authorized,
.text-status-processing {
	color: $color-primary !important;
}

.text-status-three-ds-authenticated {
	color: $color-status-three-ds-authenticated !important;
}

.text-payment-route-rule-type-block,
.text-status-declined-due-to-3-d-auth-failure,
.text-status-declined-do-not-try-again,
.text-status-declined-by-timeout,
.text-status-declined-due-to-invalid-credit-card,
.text-status-declined-due-to-error,
.text-status-declined {
	color: $color-danger !important;
}

.text-status-refunded {
	color: $color-status-refund;
}

.text-status-void,
.text-status-chargeback {
	color: black;
}

.text-status-enabled,
.text-status-approved,
.text-status-partly-refunded,
.text-success {
	color: mat.get-color-from-palette($success-palette, 700);
}

.text-status-init,
.text-status-three-ds-init,
.text-status-inactive,
.text-status-not-active,
.text-status-disabled,
.text-status-filtered-by-rule,
.text-status-not-connected {
	color: $color-mediumgray !important;
}

.text-status-cancelled,
.text-status-skipped-due-to-missing-fingerprint {
	color: $color-warning !important;
}

.risk-middle {
	background: $color-warning;
}
