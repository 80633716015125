@use '@angular/material' as mat;
@use '../../importable' as *;

.mat-typography bp-notifications-hub {
	bp-notifications-hub-header {
		.mat-toolbar {
			h2 {
				@include mat.typography-level($laptop-base-typography-config, headline);
				margin: 0;
			}
		}
	}

	bp-notifications-hub-body {
		> h1.no-notifications {
			margin-top: 40px;
		}
	}
}
