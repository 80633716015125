/**
 * This file is expected to be imported from regular MA, so basic MA styles are already present atm.
 * We need just to restyle differences to achieve same look as brand new ma-v3.
 */
@use '@angular/material' as mat;
@use '../importable' as *;
@use './fonts';

@include mat.legacy-typography-hierarchy($laptop-base-typography-config, '.v3');

html:has(.v3) {
	min-width: auto;
}

.v3 {
	// region Material theme
	// Note we include only colors so density styles are not duplicated. Same for other themes inclusions.
	@include mat.legacy-core-color($main-theme);
	@include mat.all-legacy-component-colors($main-theme);
	// endregion

	@include mat.all-legacy-component-typographies($main-theme);

	@import './utils';
	@import './theming';
	@import './material-themes';
	@import './grid-system';
	@import './restyling';
}
