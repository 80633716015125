.bp-round-button {
	&:not(.feature-under-development) {
		box-shadow: none !important;
	}

	&.mat-button-base {
		padding: 0.175em 1em;
	}
}

.mat-flat-button:not(.feature-under-development) {
	box-shadow: none !important;
}

bp-pending-icon-btn .mat-spinner.mat-progress-spinner {
	&,
	svg {
		max-width: 1.5rem;
		max-height: 1.5rem;
	}
}
