@use '@angular/material' as mat;
@use '../../../importable' as *;

.mat-menu-panel {
	@include popupBorder();
	min-height: 0 !important;

	.mat-menu-content:not(:empty) {
		&:has(.mat-menu-item) {
			padding: 0;
		}

		.mat-menu-item:hover:not([disabled]),
		.mat-menu-item.cdk-program-focused:not([disabled]),
		.mat-menu-item.cdk-keyboard-focused:not([disabled]),
		.mat-menu-item-highlighted:not([disabled]) {
			color: $color-primary;
			background-color: $color-grey-hover;
		}
	}
}

.mat-select-panel,
.mat-autocomplete-panel {
	@include popupBorder();
	min-height: 0 !important;

	mat-option.mat-option {
		// By default material sets font size as subheading-2 for autocomplete panel
		@include mat.typography-level($laptop-base-typography-config, body-1);

		&:hover:not([disabled]),
		&.mat-selected,
		&.selected,
		&.mat-active {
			color: $color-primary !important;
			background-color: $color-grey-hover !important;
		}

		> .mat-option-text {
			> * {
				line-height: 1;
			}

			.mat-option-text-content:has(+ .mat-caption) {
				font-weight: $font-weight-extra-bold;
			}

			.mat-option-text-content ~ .mat-caption {
				color: $color-dark-primary-text !important;
			}
		}
	}
}

.mat-list-base .mat-list-option {
	@include mat.typography-level($laptop-base-typography-config, body-1);
}
