@use '../importable' as *;

@font-face {
	font-family: 'Nacelle';
	src: url('/assets/v3/fonts/Nacelle/Nacelle-Light.otf') format('opentype');
	font-weight: $font-weight-slim;
	font-style: normal;
}

@font-face {
	font-family: 'Nacelle';
	src: url('/assets/v3/fonts/Nacelle/Nacelle-Regular.otf') format('opentype');
	font-weight: $font-weight-normal;
	font-style: normal;
}

@font-face {
	font-family: 'Nacelle';
	src: url('/assets/v3/fonts/Nacelle/Nacelle-Bold.otf') format('opentype');
	font-weight: $font-weight-bold;
	font-style: normal;
}

@font-face {
	font-family: 'Nacelle';
	src: url('/assets/v3/fonts/Nacelle/Nacelle-Bold.otf') format('opentype');
	font-weight: $font-weight-extra-bold;
	font-style: normal;
}
