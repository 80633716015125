@use '../../importable' as *;

.mat-card-content-table {
	table {
		th,
		td {
			color: $color-dark-primary-text;
		}

		tr {
			height: auto;

			&.clickable {
				&.active td {
					background: $color-grey-hover;
				}
			}

			&.highlight-on-hover:hover td {
				background: $color-grey-hover;
			}

			&.clickable:not(.active):hover td {
				background: $color-grey-hover;
			}
		}
	}
}
