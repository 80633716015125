@use '../../importable' as *;

bp-entities-table .page-table {
	// Note header is sticky, so content of the table on scroll should go under. We achieve that by setting
	// white background. However, setting white background to header only breaks bp-thin-scrollbar class due
	// to the way Chrome renders it (so sticky header overlaps it for .5px or so). So we have to set white
	// background to the whole table to negate this unwanted behaviour.
	background-color: white;

	thead {
		position: sticky;
		top: 0;
		z-index: $z-index-backdrop - 1;

		th {
			&.mat-column-pspName {
				text-align: center;

				.mat-sort-header-container {
					justify-content: center;
				}
			}
		}
	}

	td {
		padding-inline: 0.5rem;

		&:first-of-type {
			padding-left: 1.5rem;
		}

		&:last-of-type {
			padding-right: 1.5rem;
		}

		bp-property-metadata-view > div > div {
			@include text-ellipsis();
		}

		//region Custom columns
		&.mat-column-status {
			max-width: 125px;
			text-align: left;

			bp-transaction-status {
				max-width: 100%;
			}
		}

		&.mat-column-paymentMethod {
			max-width: 125px;
		}

		&.mat-column-country bp-country {
			font-size: 38px;
		}
		//endregion
	}
}
