input {
	&:-webkit-autofill {
		-webkit-background-clip: text;
		-webkit-text-fill-color: currentColor;

		&:disabled {
			-webkit-text-fill-color: $color-dark-disabled-text;
		}
	}
}

fieldset {
	margin: 0;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

textarea.mat-input-element {
	line-height: $body-line-height;
}
