@use '@angular/material' as mat;
@use 'theming';

@mixin subheaders() {
	@for $i from 2 through 5 {
		h#{$i},
		.mat-h#{$i} {
			@content;
		}
	}
}

@mixin line-clamp($lines) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin iconBtnColor() {
	color: theming.$color-grey-primary-text;

	&:hover {
		color: theming.$color-dark-primary-text;
	}
}

@mixin popupBorder() {
	// Note we can't add border to general popup class, because for some reason popup content can be
	// wider than this container on wide dropdowns (e.g. in add route popup countries filter).
	border: 1px solid theming.$color-primary;
	border-radius: 5px !important;
}
