@use '@angular/material' as mat;
@use '../../../importable' as *;

.form-field-appearance-round {
	> mat-form-field {
		//region Colors and transitions
		.mat-form-field-outline {
			// To prevent background blinking on blue background
			transition-property: color, background !important;
			transition-duration: 0.225s, 0s !important;
			color: transparent;

			> div {
				background: $color-grey-control-bg;
			}

			&.mat-form-field-outline-thick {
				color: $color-grey-outline-thick;

				> * {
					border-width: 1px;
				}
			}
		}

		.mat-form-field-label-wrapper mat-label {
			color: $color-grey-primary-text;
		}

		&.mat-form-field-can-float {
			.mat-form-field-label-wrapper mat-label {
				transition: background $animation-duration ease-out;
				border-radius: 10px;
			}
		}

		&.mat-form-field-should-float {
			.mat-form-field-outline-gap {
				width: 0 !important;
			}

			.mat-form-field-label {
				mat-label {
					background: white;
					padding: 1px 5px;
				}
			}
		}

		&.mat-form-field-disabled {
			input,
			.mat-select-value,
			.mat-form-field-label-wrapper mat-label,
			mat-chip,
			.mat-chip,
			.mat-chip.mat-standard.chip {
				color: $color-grey-inactive;
			}
		}

		&:not(.mat-form-field-disabled) {
			input,
			.mat-select-value {
				color: $color-dark-primary-text;
			}

			mat-chip,
			.mat-chip,
			.mat-chip.mat-standard.chip {
				color: $color-grey-primary-text;
				background: white;

				&:after {
					background: white;
				}
			}
		}
		//endregion

		//region Density and sizes
		.mat-form-field-infix {
			padding: 0.35em 0 !important;
			min-height: 3.142857em !important;
		}

		&.mat-form-field-should-float {
			.mat-form-field-label {
				top: 1.4em !important;
			}
		}
		//endregion

		//region Name control in modal headers
		&.auto-grow {
			.mat-form-field-infix {
				border-top-width: 0.35em;
			}
		}
		//endregion
	}

	&.white-input {
		> mat-form-field {
			.mat-form-field-outline {
				> div {
					background: white !important;
				}
			}
		}
	}
}

// region Date range
.daterange-appearance-round {
	background: $color-grey-control-bg;
	padding-top: 9px;
	padding-bottom: 9px;

	.form-field-outline {
		border-color: transparent !important;
	}

	.form-field-outline-thick {
		border-color: $color-grey-outline-thick !important;
		border-width: 1px !important;
	}

	.clear-button-placeholder {
		.clear-button-wrapper {
			right: 6px !important;

			button {
				@include size(24px !important);
			}
		}
	}
}
// endregion
