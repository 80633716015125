@use '@angular/material' as mat;

// Inspired by https://getbootstrap.com/docs/5.3/helpers/color-background/
@each $type, $scheme in $bp-colors-palette {
	.text-bg-#{$type} {
		color: white;

		@if $type == 'success' {
			background: mat.get-color-from-palette($scheme, 700);
		} @else {
			background: mat.get-color-from-palette($scheme, 500);
		}
	}
}
