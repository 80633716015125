@use '../../importable' as *;

.color-primary {
	color: $color-primary !important;
}

.color-white {
	color: white !important;
}

.color-primary-text {
	color: $color-dark-primary-text !important;
}

.color-grey-primary-text {
	color: $color-grey-primary-text !important;
}

.color-grey-inactive {
	color: $color-grey-inactive !important;
}

.color-danger {
	color: $color-danger !important;
}
