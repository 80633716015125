$margin-v: 1.6667em;

.rich-text {
	table {
		overflow-wrap: break-word;
		margin-bottom: $margin-v;

		tr {
			border-color: $color-border;
			th,
			td {
				padding: 0.5rem;
				border-color: $color-border;
			}
		}
	}

	p {
		margin-bottom: $margin-v;
	}

	ol,
	ul,
	blockquote {
		margin: $margin-v 0;
	}

	blockquote {
		border-left: 4px solid $color-border;
		padding-left: 1.25rem;
	}

	ul,
	ol {
		padding-left: 1.5em;
		margin: $margin-v 0;
	}

	li {
		margin-bottom: 0.5rem;
	}

	ul > li {
		list-style-type: disc;
	}
}
