@use '../../importable' as *;

@include subheaders() {
	text-transform: uppercase;
	color: $color-grey-primary-text;
}

// Restore default mat colors to not break existing theme (e.g. for announcements and news)
.mat-toolbar.mat-primary {
	@include subheaders() {
		color: var(--mat-toolbar-container-text-color, white);
	}
}
