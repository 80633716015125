@use 'sass:math';
@use '@angular/material' as mat;

.properties-metadata-section-layout {
	.row {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
		gap: $right-drawer-card-padding-x;

		&:not(:empty) {
			margin-bottom: 1rem;

			&:last-child {
				margin-bottom: 3px; // 3px for the feature-under-development shadow
			}
		}

		+ .row {
			margin-top: 0.25rem;
		}

		> * {
			flex: 1;
		}

		> .control.w-100 > * {
			width: 100%;
		}

		> :first-child:not(.control-type-checkbox):not(.control-type-switch) {
			+ .control-type-switch {
				margin-top: 1rem;
			}
			+ .control-type-checkbox {
				margin-top: 1.2rem;
			}
		}

		.control-type-button-toggle {
			margin-bottom: 26px;
		}

		.view-type-text {
			overflow-wrap: break-word;
		}

		.view-type-boolean {
			align-self: center;
		}
	}

	.property-view-caption {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 0.85em;
		color: $color-mediumgray;
		gap: 0.5rem;
		margin-bottom: 0.2em;

		> mat-icon {
			margin-left: 0.2em;
		}
	}
}
